import Axios from 'axios'

export function request(config) {
    const axios = new Axios.create({
        baseURL: process.env.VUE_APP_API_ROOT,
        timeout: 50000
    })

    axios.interceptors.request.use(config => {
        //console.log(config)
        return config
    }, err => {
        console.log(err)
    })

    axios.interceptors.response.use(res => {
        //console.log(res)
        return res
    }, err => {
        console.log(err)
    })

    return axios(config)
}

export default {
    data() {
        return "my request"
    }
}