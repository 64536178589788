import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    student: {}
  },
  mutations: {
    saveStudent(state, student) {
      state.student = student
    }
  },
  actions: {},
  modules: {}
})