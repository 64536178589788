import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err)
// }

const routes = [{
    path: '/',
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: "/list",
    component: () => import('../views/admin.vue'),
    children: [{
        name: "list",
        path: '/list',
        component: () => import('../views/list.vue')
      },
      {
        name: "export",
        path: '/export',
        component: () => import('../views/export.vue')
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    return next()
  } else {
    //debugger
    let token = window.sessionStorage.getItem("token")
    if (token) {
      return next()
    } else {
      return next("/login")
    }
  }
})

export default router